export default (ctx, inject) => {
  // lodash templating
  const brand = {"name":"BerufExperten","about":"Über BerufExperten","slogan":"Von der Community empfohlen","webpage":"BerufExperten.de","logo":{"part1":"BERUF","part2":"EXPERTEN"},"fromMate":"Von unseren BerufExperten Fachleute!","since":"2024","themeColor":"#337ab7","company":{"name":"JóSzaki Kft.","address":"Lágymányosi utca 12. Fsz. 2. ajtó, H-1111 Budapest, Ungarn","registrationAuthority":"Fővárosi Törvényszék Cégbírósága","registrationNumber":"01-09-328629","taxNumber":"26495248-2-42","hosting":"Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) - German Data Center"}};

  const brandName =  "berufexperten-de";
  const isSuperprofikCz = brandName === "superprofik-cz";
  const isBerufExperten = brandName === "berufexperten-de";
  const isJoszaki = brandName === "joszaki";

  inject('brand', brand);
  inject('branding', {
    name: brandName,
    isSuperprofikCz,
    isBerufExperten,
    isJoszaki
  })
};